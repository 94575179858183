import * as React from 'react';

import Container from '@mui/material/Container';

import NavigationRow from '../NavigationRow';

// create an element that will be used for paragraphs

export default function PrivacyPolicy() {

  return (
    <div
      style={{
        backgroundColor: 'rgb(250,250,250)',
        height: '100%',
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          pb: 10,
        }}
      >
        <div className="my-4">
    <h1>Privacy Policy</h1>

    <h2>1. Introduction</h2>
    <p>
        Tetun.org is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our services, including our website, Android app, and iOS app.
    </p>

    <h2>2. Information We Collect</h2>
    <ul>
        <li><strong>Personal Information:</strong> We do not collect personal information, such as your name, email or IP address, when you use this service. </li>
        <li><strong>Usage Data:</strong> We collect data on how you interact with our services, including the text you translate, and app usage statistics.</li>
    </ul>

    <h2>3. Use of Translated Text</h2>
    <p>
        We reserve the right to use translated text for research and product improvement purposes. All translated text is processed anonymously, ensuring that no personal identifiers are linked to the content.
    </p>

    <h2>4. Data Sources</h2>
    <p>
        Our translations are based on data from DIT, INL (Instituto Nacional de Linguística), and other ground work. We adhere to the official Government of Timor-Leste spelling standards provided by INL.
    </p>

    <h2>5. How We Use Your Information</h2>
    <ul>
        <li>To improve and maintain our services.</li>
        <li>To conduct research and enhance translation quality.</li>
        <li>To communicate with you regarding updates or responses to your inquiries.</li>
    </ul>

    <h2>6. Data Security</h2>
    <p>
        We implement industry-standard security measures to protect your data from unauthorized access, disclosure, or destruction.
    </p>

    <h2>7. Third-Party Links</h2>
    <p>
        Our services may contain links to third-party websites. We are not responsible for the privacy practices of these third-party sites.
    </p>

    <h2>8. Changes to This Policy</h2>
    <p>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you of significant changes through our services.
    </p>

    <h2>9. Contact Us</h2>
    <p>
        For any concerns, comments or suggestions, contact us through our <a href="https://www.facebook.com/yourpage">Facebook Page</a> or email us at <a href="mailto:info@tetun.org">info@tetun.org</a>.
    </p>
        </div>
      </Container>
      <NavigationRow />
    </div>
  );
}
